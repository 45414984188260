export function mv_slide() {
  //slickスライダー初期化
  $('.p-mv_slide').slick({
    arrows: false,
    dots: true,
    appendDots: $('.p-mv_bar'),
    dotsClass: "p-mv_bar--list",
    autoplaySpeed: 5900,
    speed: 600,
    fade: true,
    autoplay: true,
    pauseOnHover: false,
    pauseOnFocus: false,
  });
}